import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './UniswapSniper.css';  // Import the CSS file

const Web3 = require('web3');

const UniswapSniper = () => {
  const [results, setResults] = useState([]);
  const [totalEth, setTotalEth] = useState(0);
  const [loading, setLoading] = useState(false);
  
  // New state variables for inputs
  const [snipe, setSnipe] = useState('1199996');
  const [reserveEth, setReserveEth] = useState('5');
  const [reserveToken, setReserveToken] = useState('70000000');
  const [wallets, setWallets] = useState(44);  // Added state for wallets

  const Uv2 = "0x86dcd3293C53Cf8EFd7303B57beb2a3F671dDE98";  // Uniswap contract address
  const abi = [
    {
        "inputs": [
            { "internalType": "uint256", "name": "amountOut", "type": "uint256" },
            { "internalType": "uint256", "name": "reserveIn", "type": "uint256" },
            { "internalType": "uint256", "name": "reserveOut", "type": "uint256" }
        ],
        "name": "getAmountIn",
        "outputs": [{ "internalType": "uint256", "name": "amountIn", "type": "uint256" }],
        "stateMutability": "pure",
        "type": "function"
    }
  ];

  const web3 = new Web3(new Web3.providers.HttpProvider('https://sepolia.infura.io/v3/2fa7ead4d8ea4a74a534f8dbb395674c'));
  const contract = new web3.eth.Contract(abi, Uv2);

  const fetchSnipingData = async () => {
    setLoading(true);

    let snipeWei = web3.utils.toWei(snipe, 'ether');
    let reserveEthWei = web3.utils.toBN(web3.utils.toWei(reserveEth, 'ether'));
    let reserveTokenWei = web3.utils.toBN(web3.utils.toWei(reserveToken, 'ether'));

    let total = web3.utils.toBN('0');
    const arr = [];

    for (let i = 1; i <= wallets; i++) {
      try {
        const balance = await contract.methods.getAmountIn(snipeWei, reserveEthWei, reserveTokenWei).call();
        arr.push({ wallet: i, balance: web3.utils.fromWei(balance, 'ether') });

        reserveEthWei = reserveEthWei.add(web3.utils.toBN(balance));
        reserveTokenWei = reserveTokenWei.sub(web3.utils.toBN(snipeWei));

        total = total.add(web3.utils.toBN(balance));
      } catch (err) {
        console.error(`Error in wallet ${i}:`, err);
      }
    }

    setResults(arr);
    setTotalEth(web3.utils.fromWei(total, 'ether'));
    setLoading(false);
  };

  const downloadTxt = () => {
    const content = results.map(result => `Wallet ${result.wallet}: ${result.balance} ETH`).join('\n');
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "snipe-results.txt");
  };

  return (
    <div className="container">
      <h2>Bundles Sniping Simulation</h2>

      <div className="input-container">
        <label htmlFor="wallets">Number of Wallets:</label>
        <input
          id="wallets"
          type="number"
          value={wallets}
          onChange={(e) => setWallets(e.target.value)}
        />

        <label htmlFor="snipe">Token to snipe per wallet:</label>
        <input
          id="snipe"
          type="text"
          value={snipe}
          onChange={(e) => setSnipe(e.target.value)}
        />

        <label htmlFor="reserveEth">Initial ETH LP:</label>
        <input
          id="reserveEth"
          type="text"
          value={reserveEth}
          onChange={(e) => setReserveEth(e.target.value)}
        />

        <label htmlFor="reserveToken">Initial Token LP:</label>
        <input
          id="reserveToken"
          type="text"
          value={reserveToken}
          onChange={(e) => setReserveToken(e.target.value)}
        />
      </div>

      <button onClick={fetchSnipingData} disabled={loading} className="btn">
        {loading ? 'Loading...' : 'Fetch Sniping Data'}
      </button>

      {results.length > 0 && (
        <>
          <div className="table-container">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Wallet</th>
                  <th>ETH Needed</th>
                </tr>
              </thead>
              <tbody>
                {results.map(result => (
                  <tr key={result.wallet}>
                    <td>{result.wallet}</td>
                    <td>{result.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h3>Total ETH Needed: {totalEth} ETH</h3>

          <div className="chart-container">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={results} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="wallet" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="balance" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <button onClick={downloadTxt} className="btn">
            Download Results as TXT
          </button>
        </>
      )}
    </div>
  );
};

export default UniswapSniper;